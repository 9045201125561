<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="content content-static" v-if="active" id="scrollcontainer"  ref="container" v-on:scroll="measure()">
   <transition name="content-loading-fade">
        <div class="content-loading" v-if="loading"></div>
    </transition>
    <div class="content-fix-menu" v-if="post">
        <router-link 
            tag="a"
            v-bind:key="key" 
            :to="{ name: $route.name, params: { lang: $root.language[$root.languageid], type: $route.name, name: $root.u(row.Contribution.Name),  id: row.Contribution.Id }}"
            v-for="(row, key) in post.Documents"
        >
                <span 
                  class="content-fix-menu-link content-fix-menu-link-large" 
                  ref="sublink"
                  v-scroll-to="{el: `#element${row.Contribution.Id}`, container: '#scrollcontainer', offset: -90}" 
                  v-html="row.Data.Titel.Content[$root.languageid]"
                  style="white-space: nowrap;"
                ></span>
        </router-link>  
    </div>
    <div class="content-fix-content" v-if="post">
      <div 
        class="content-fix-content-document content-padding-bottom-double  content-margin-bottom-double"
        v-bind:key="key" 
        ref="anchor"
        v-for="(row, key) in post.Documents"
      >
        <h1 :ref="`element${row.Contribution.Id}`" :id="`element${row.Contribution.Id}`" style="white-space: nowrap;" class="lauftext lauftext-padding-bottom content-fix-content-section" v-html="row.Data.Titel.Parsed[$root.languageid]"></h1>
        <div v-if="row.Contribution.Fortemplate == 6">
            <div  v-if="row.Data.Bild.Content && row.Data.Bild.Content[0]" class="swiper">
                <swiper ref="myswiper" :options="$root.swiperOption">
                  <swiper-slide :data-caption="img.Captions[$root.languageid]"  :data-hash="`img-${$root.u(row.Contribution.Name)}-${index}`" v-bind:key="index"  v-for="(img, index) in row.Data.Bild.Content">
                    <div class="img" :class="img.Sizes[0] > img.Sizes[1] ? 'img-landscape' : 'img-portrait'">
                        <img class="img-container" :data-src="img.Files.Resized[2]"/>
                    </div>
                  </swiper-slide>
                  <div class="swiper-button-prev swiper-button-black" slot="button-prev"></div>
                  <div class="swiper-button-next swiper-button-black" slot="button-next"></div>
                  <div class="swiper-pagination swiper-pagination-small"  slot="pagination"></div>
                </swiper>
            </div>          
          <div class="lauftext" v-html="row.Data.Text.Parsed[$root.languageid]"></div>

          <div class="lauftext" v-if="row.Data.Download.Content && row.Data.Download.Content.length > 0">
            <p v-bind:key="imgid" v-for="(img, imgid) in row.Data.Download.Content">
              <a 
              v-html="img.Captions[$root.languageid]"
              target="_blank"
              :href="img.Files.Original"
              v-if="img.Captions[$root.languageid]"
              >
              </a>
            </p>
          </div>

        </div>
        <div class="content-static-expand" v-if="row.Contribution.Fortemplate == 10">
          <div class="lauftext" v-html="row.Data.Text.Parsed[$root.languageid]"></div>
          <div class="content-static-card" v-bind:key="index"  v-for="(img, index) in row.Data.Bild.Content">
            <div class="img" :class="img.Sizes[0] > img.Sizes[1] ? 'img-landscape' : 'img-portrait'">
              <div class="img-container" :style="{backgroundImage: `url(${img.Files.Resized[0]})`}"></div>
            </div>
            <p class="lauftext lauftext-medium lauftext-padding-top lauftext-padding-bottom-double" v-html="img.Parsed[$root.languageid]">
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// require styles
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo)

export default {
  components: {
    swiper,
    swiperSlide
  },
  data () {
    return {
      loading: false,
      post: null,
      error: null,
      active: false,
      markedlink: false,
      swiperOption: {
        autoHeight: false, //enable auto height
        spaceBetween: 20,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  created () {
    if (this.active != this.$route.params.id || this.$route.params.id == null) {
        this.post = null
        this.fetchData()
    }
  },
  watch: {
    '$route' () {
        if (this.active != this.$route.params.id  || this.$route.params.id == null) {
          this.error = this.post = null
          this.fetchData()          
        }
    }
  },
  methods: {
    measure() {
      this.$refs.anchor.forEach((c,i) => {
        if (c.offsetTop - this.$refs.container.scrollTop < this.$refs.sublink[i].getBoundingClientRect().top + 50) {
          this.$refs.sublink[i].classList.add("content-fix-menu-link-hidden");
        }
        else {
          this.$refs.sublink[i].classList.remove("content-fix-menu-link-hidden");
        }
      })
    },
    fetchData() {
      this.loading = true
      this.active = this.$route.params.id || "emtpy"
      try {
        this.$store.dispatch('loadData', {
          mode: 'contributions', 
          issue: this.$route.meta.issue.Id, 
          chapter: this.$route.meta.chapter.Id 
        }).then((data) => {
          this.post = data
          this.loading = false;
          if (!this.$route.params.id) {
            this.$root.addBreadcrumb(this.$route.meta.translation);
            this.$root.applyMeta(`${this.$route.meta.translation[this.$root.languageid]} - ${this.$root.metatags.title[this.$root.languageid]}`)
          } else {
            this.post.Documents.forEach((_d) => {
              if (_d.Contribution.Id == this.$route.params.id) {
                this.$root.addBreadcrumb(_d.Data.Titel.Parsed);
                this.$root.applyMeta(`${_d.Data.Titel.Content[this.$root.languageid]} - ${this.$root.metatags.title[this.$root.languageid]}`)
              }
            })
          }
          
          this.$nextTick(() => {
            this.measure()
            if (this.$route.params.id) {
              this.$scrollTo(this.$refs[`element${this.$route.params.id}`][0], 1, {container: '#scrollcontainer', offset: -90})
            }
          })
        });  
      } catch (error) {
        this.error = error;
      }      
      
    }
  }
}
</script>


<style lang="scss">

  @import "../main";
  
  .content {
    &-static {
      &-expand {
        margin-left: -$margin-standard / 2;
        margin-right: -$margin-standard / 2;
      }
      &-card {
        display: inline-block;
        width: 25%;
        padding-left: $margin-standard / 2;
        padding-right: $margin-standard / 2;
        cursor: pointer;
        @include lg() {
          width: 33%;
        }
        @include st() {
          width: 50%;
        }
        vertical-align: top;
        .img {
          &-landscape {
            width: 100%;
            padding-bottom: 75%;
          }
          &-portrait {
            width: 57%;
            padding-bottom: 75%;
          }
          position: relative;
          margin: auto;
          &-container {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
    }
  }
  

</style>